import { Link } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks";
import cardStyles from "../../../styles/card.module.css";
import { bagstageApplications } from "../../../utils/commonUtil";

export interface cardListItem {
  appRef: string;
  applicationTitle: string;
  redirectUrl: string;
  icon: React.ReactNode;
}

export default function CardListItem({
  cardItems
}: {
  cardItems: cardListItem;
}) {
  const { appRef, redirectUrl, icon, applicationTitle } = cardItems;
  const { isLogin } = useAppSelector((state) => state.authenticationSlice);
  const { enabledProducts } = useAppSelector(
    (state) => state.enabledProductsSlice
  );

  return (
    <>
      {/* TODO: check if appRef is arrivalViewOnlyOperator to keep arrivalViewOnlyOperator open for all users. */}
      {isLogin && enabledProducts?.includes(appRef) ? (
        <Link to={redirectUrl} className={cardStyles.cardListItem}>
          {icon}
          {applicationTitle}
        </Link>
      ) : (
        <div
          className={`${cardStyles.cardListItem} cursor-not-allowed opacity-30`}
        >
          {icon}
          {applicationTitle}
        </div>
      )}
    </>
  );
}
