import { configureStore, combineReducers } from "@reduxjs/toolkit";
import authenticationSlice from "./auth";
import enabledProductsSlice from "./enabledProducts";
import toastNotificationsSlice from "./toastNotifications";

const store = configureStore({
  reducer: combineReducers({
    authenticationSlice,
    enabledProductsSlice,
    toastNotificationsSlice
  }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false // to avoid error while storing authenticationSlice.kcObject.
    })
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
