import wrapperStyles from "../../styles/subPageWrapper.module.css";

export default function SubPageWrapper({
  children,
  classes
}: {
  children: React.ReactNode | React.ReactNode[];
  classes?: string;
}) {
  const classList = `${wrapperStyles.subPageWrapper} ${classes || ""}`;
  return <section className={classList}>{children}</section>;
}
