import { useEffect, useRef } from "react";
import buttonStyles from "../../../styles/button.module.css";
import { iconButtonVariantTypes } from "../../../types/common";
import {
  determineVariantClassForIconButton,
  updateIconColor,
  getIconButtonSize
} from "../../../utils/buttonUtil";
import { iconButtonSizeTypes } from "../../../types/common";

export default function IconButton({
  classes,
  disable,
  variant,
  onClick,
  icon,
  iconColor,
  size
}: {
  classes?: string;
  disable?: boolean;
  variant?: iconButtonVariantTypes;
  icon: React.ReactNode;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void | (() => void); // accepts any function regardless of its parameters.
  iconColor?: string;
  size?: iconButtonSizeTypes;
}) {
  const iconButton = useRef(null);

  useEffect(() => {
    if (iconButton.current)
      updateIconColor(iconButton.current, iconColor, variant, disable);
  }, [iconButton, iconColor, variant, disable]);

  return (
    <button
      ref={iconButton}
      className={`
      ${buttonStyles.iconButton}
      ${determineVariantClassForIconButton(variant)}
      ${getIconButtonSize(size)}
      ${classes || ""}
      `}
      onClick={onClick}
      disabled={disable}
    >
      {icon}
    </button>
  );
}
