import { createBrowserRouter, RouterProvider } from "react-router-dom";
import HomePage from "./pages/HomePage";
import { ErrorPage } from "./pages/ErrorPage";
import RedirectToUserManagement from "./pages/RedirectToUserManagement";
import RedirectToArrivalAnalyst from "./pages/RedirectToArrivalAnalyst";
import RedirectToTransferQTB from "./pages/RedirectToTransferQTB";

import "./service/translations/translationService";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import { addKeycloakInterceptorWithKeycloakInstance } from "./service/api";
import useAuth from "./hooks/useAuth";
import { getEnabledProducts } from "./store/enabledProducts/action";

const router = createBrowserRouter([
  { path: "/", element: <HomePage /> },
  { path: "/user-management", element: <RedirectToUserManagement /> },
  { path: "/arrival-analyst", element: <RedirectToArrivalAnalyst/> },
  { path: "/transfer-qtb", element: <RedirectToTransferQTB/> },
  { path: "error", element: <ErrorPage />},
  { path: "*", element: <ErrorPage /> }
]);

const App = () => {
  const { login } = useAuth();
  const { kcObject } = useAppSelector((state) => state.authenticationSlice);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (login && kcObject) {
      addKeycloakInterceptorWithKeycloakInstance(kcObject);
      dispatch(getEnabledProducts());
    }
  }, [kcObject, login, dispatch]);

  return (
    <main>
      <RouterProvider router={router} />
    </main>
  );
};
export default App;
