import {
  commonButtonAndIconVariantTypes,
  iconButtonSizeTypes,
  iconButtonVariantTypes,
  standardButtonVariantTypes
} from "../types/common";
import buttonStyles from "../styles/button.module.css";

// Function to determine class based on standard button type and variant
export const determineVariantClassForStandardButton = (
  variant?: standardButtonVariantTypes
) => {
  switch (variant) {
    case "primary":
      return buttonStyles.standardButtonPrimary;
    case "secondary":
      return buttonStyles.standardButtonSecondary;
    case "tertiary":
      return buttonStyles.standardButtonTertiary;
    default:
      return buttonStyles.standardButtonSecondary;
  }
};

// Function to determine class based on icon button type and variant
export const determineVariantClassForIconButton = (
  variant?: iconButtonVariantTypes
) => {
  switch (variant) {
    case "primary":
      return buttonStyles.iconButtonPrimary;
    case "secondary":
      return buttonStyles.iconButtonSecondary;
    case "iconOnly":
      return buttonStyles.standardIcon;
    default:
      return buttonStyles.iconButtonSecondary;
  }
};

// Function to determine disabled color for icon link buttons based on variant
export const getIconLinkButtonDisableColor = (
  variant?: iconButtonVariantTypes
): string => {
  switch (variant) {
    case "primary":
      return buttonStyles.iconLinkDisablePrimary;
    case "secondary":
      return buttonStyles.iconLinkDisableSecondary;
    case "iconOnly":
      return buttonStyles.iconOnlyLinkDisable;
    default:
      return buttonStyles.iconLinkDisableSecondary;
  }
};

// Applies a given color to the stroke or fill attribute of the path element
const applyColorToPath = (path: SVGPathElement, color: string): void => {
  const stroke = path.getAttribute("stroke");
  const fill = path.getAttribute("fill");

  if (stroke) {
    path.setAttribute("stroke", color);
  } else if (fill) {
    path.setAttribute("fill", color);
  }
};

// Sets the color of a disabled icon button based on its variant and stores the original color
const setDisabledIconButtonColor = (
  path: SVGPathElement,
  variant?: commonButtonAndIconVariantTypes
) => {
  let color: string;
  if (variant === "primary") {
    color = "var(--color-grey-scale-2)";
  } else {
    color = "var(--color-grey-scale-3)";
  }
  return applyColorToPath(path, color);
};

// Updates the colors of all paths within a parent container element based on various conditions
export const updateIconColor = (
  parentContainer: HTMLElement | SVGSVGElement,
  iconColor?: string,
  variant?: commonButtonAndIconVariantTypes,
  disable?: boolean
) => {
  const paths = parentContainer.querySelectorAll("path");

  paths.forEach((path) => {
    if (disable) {
      return setDisabledIconButtonColor(path, variant);
    } else if (iconColor) {
      return applyColorToPath(path, iconColor);
    }
  });
};

// Determines the appropriate size class for an icon button based on its size prop
export const getIconButtonSize = (size?: iconButtonSizeTypes) => {
  if (size === "small") {
    return buttonStyles.smallIconButton;
  } else {
    return buttonStyles.bigIconButton;
  }
};

// Function to determine disabled color for standard link buttons based on variant
export const getStandardLinkButtonDisableColor = (
  variant?: standardButtonVariantTypes
): string => {
  switch (variant) {
    case "primary":
      return buttonStyles.standardLinkDisablePrimary;
    case "secondary":
      return buttonStyles.standardLinkDisableSecondary;
    case "tertiary":
      return buttonStyles.standardLinkDisableTertiary;
    default:
      return buttonStyles.standardLinkDisableSecondary;
  }
};
