export enum languages {
  en = "en",
  da = "da"
}

export const error404Info = {
  errorCode: 404,
  errorMessage: "404-error-message",
  errorSuggestion: "error-suggestion"
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

//Generate a random id using a combination of characters and math.random returned number
export const generateRandomID = (length = 10) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters[randomIndex];
  }
  return result;
};

// list of all applications under bagstage
export enum bagstageApplications {
  arrivalOperator = "arrival-operator",
  arrivalAnalyst = "arrival-analyst",
  arrivalViewOnlyOperator = "arrival-view-only-operator",
  departureAnalyst = "departure-analyst",
  transferQtb = "transfer-qtb",
  userManagement = "user-management"
}
