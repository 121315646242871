import { useEffect, useRef } from "react";
import buttonStyles from "../../../styles/button.module.css";
import { standardButtonVariantTypes } from "../../../types/common";
import {
  determineVariantClassForStandardButton,
  updateIconColor
} from "../../../utils/buttonUtil";

export default function StandardButton({
  children,
  classes,
  disable,
  variant,
  onClick,
  icon,
  iconColor
}: {
  classes?: string;
  disable?: boolean;
  variant?: standardButtonVariantTypes;
  children: string;
  onClick: () => void;
  icon?: React.ReactNode;
  iconColor?: string;
}) {
  const standardButton = useRef(null);

  useEffect(() => {
    if (standardButton.current)
      updateIconColor(standardButton.current, iconColor, variant);
  }, [standardButton]);

  return (
    <button
      ref={standardButton}
      className={`
      ${buttonStyles.standardButton}
      ${determineVariantClassForStandardButton(variant)}
      ${classes || ""}
      `}
      onClick={onClick}
      disabled={disable}
    >
      {icon}
      {children}
    </button>
  );
}
