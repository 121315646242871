import React, { useEffect, useRef, useState, useCallback } from "react";
import { ReactComponent as CloseIcon } from "../../../assets/icons/bagstage_close.svg";
import toastStyles from "../../../styles/toast.module.css";
import { useAppDispatch } from "../../../store/hooks";
import ToastNotificationService from "../../../service/toastNotificationService";
import { deleteToast } from "../../../store/toastNotifications";
import IconButton from "../buttons/IconButton";

export type toastTypes = "error" | "notification" | "warning";

export type toastComponentTypes = {
  toastId: string;
  toastMessage: string;
  toastType: toastTypes;
};

const Toast = React.memo(
  ({ toastId, toastMessage, toastType }: toastComponentTypes) => {
    const timerID = useRef<NodeJS.Timeout | null>(null);
    const [isRemoving, setIsRemoving] = useState(false);

    const dispatch = useAppDispatch();

    const removeToast = useCallback(() => {
      //To add the effect moving out of the view, we add a delay between the starting the animation and actually deleting the item.
      setIsRemoving(true);
      setTimeout(() => {
        dispatch(deleteToast(toastId));
      }, 300);
    }, [dispatch, toastId]);

    useEffect(() => {
      timerID.current = setTimeout(() => {
        removeToast();
      }, 10000);
      return () => {
        if (timerID.current) {
          clearTimeout(timerID.current);
        }
      };
    }, [removeToast]);

    return (
      <div
        className={`
          ${toastStyles.toast}
          ${isRemoving ? toastStyles.outOfView : toastStyles.intoView}
          ${ToastNotificationService.getBackgroundClass(toastType)}
          `}
      >
        <div className={toastStyles.toastHeaderSection}>
          <IconButton
            classes={toastStyles.closeButton}
            variant="iconOnly"
            icon={<CloseIcon />}
            onClick={removeToast}
            iconColor={ToastNotificationService.getToastCloseIconColor(
              toastType
            )}
          />
        </div>
        <p className={toastStyles.toastMessage}>{toastMessage}</p>
      </div>
    );
  }
);

export default Toast;
