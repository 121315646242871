import pageWrapperStyles from "../../styles/pageWrapper.module.css";

export default function PageWrapper({
  children,
  classes
}: {
  children: React.ReactNode | React.ReactNode[];
  classes?: string;
}) {
  const classList = `${pageWrapperStyles.pageWrapper} ${
    classes ? classes : null
  }`;
  return <article className={classList}>{children}</article>;
}
