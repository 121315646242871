import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import buttonStyles from "../../../styles/button.module.css";
import { standardButtonVariantTypes } from "../../../types/common";
import {
  determineVariantClassForStandardButton,
  getStandardLinkButtonDisableColor,
  updateIconColor
} from "../../../utils/buttonUtil";

export default function StandardLinkButton({
  to,
  children,
  classes,
  disable,
  variant,
  icon,
  iconColor
}: {
  to: string;
  children: string;
  classes?: string;
  disable?: boolean;
  variant?: standardButtonVariantTypes;
  icon?: React.ReactNode;
  iconColor?: string;
}) {
  const iconLinkButton = useRef(null);

  useEffect(() => {
    if (iconLinkButton.current)
      updateIconColor(iconLinkButton.current, iconColor, variant, disable);
  }, [iconLinkButton, iconColor, variant, disable]);

  return disable ? (
    <div
      ref={iconLinkButton}
      className={`
        ${getStandardLinkButtonDisableColor(variant)}
        ${buttonStyles.standardButton}
        ${classes || ""}
      `}
    >
      {icon}
      {children}
    </div>
  ) : (
    <Link
      to={to}
      className={`
        ${buttonStyles.standardButton}
        ${determineVariantClassForStandardButton(variant)}
        ${classes || ""}
      `}
    >
      {icon}
      {children}
    </Link>
  );
}
