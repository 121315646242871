import axios from "axios";
import { setKcObject } from "../store/auth";
import { addToast } from "../store/toastNotifications";
import i18n from "./translations/translationService";
import { t } from "i18next";

export const baseURL = window._env_.REACT_APP_USER_MANAGEMENT_API_URL;

export const API_PATHS = {
  enabledProducts: "/user/enabled-products"
} as const;

const api = axios.create({
  baseURL
});

export function addKeycloakInterceptorWithKeycloakInstance(
  keycloakObject: any
) {
  api.interceptors.request.use(
    (config) => requestInterceptorTokenHandler(config, keycloakObject),

    (request) => {
      return request;
    }
  );
}

export function setUpCustomResponseInterceptor(dispatch: any, navigate: any) {
  api.interceptors.response.use(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => responseInterceptorTokenHandler(dispatch, error, navigate)
  );
}

/* The updateToken function is a keycloak function that checks if the token expires within any given
  timeframe (in seconds) - and if so, updates the token. */
const requestInterceptorTokenHandler = async (
  config: any,
  keycloakObject: any
) => {
  try {
    const MIN_VALIDITY_SECS = 10;
    await keycloakObject.updateToken(MIN_VALIDITY_SECS);
    const token = keycloakObject.token;
    setKcObject(keycloakObject);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers["Accept-Language"] = i18n.language;
    }
  } catch (error) {
    return keycloakObject?.logout();
  }
  return config;
};

const responseInterceptorTokenHandler = async (
  dispatch: any,
  error: any,
  navigate: any
) => {
  const statusCode = error?.response?.status;

  // Handle 5xx server errors
  if (statusCode >= 500 && statusCode < 600) {
    navigate("error", {
      state: {
        errorCode: statusCode.toString(),
        errorMessage: t("internal-server-error"),
        errorSuggestion: t("error-suggestion")
      }
    });
    return;
  }

  // if there violations, each violation is displayed as a separate toast.
  if (error?.response?.data?.violations) {
    error.response.data.violations.map((item: any) =>
      dispatch(
        addToast({
          toastType: "error",
          toastMessage: item.fieldName + " " + item.reason
        })
      )
    );
    return Promise.reject(error);
  } else {
    // else show generic error reason
    dispatch(
      addToast({
        toastType: "error",
        toastMessage: error?.response?.data?.reason || "An error occurred"
      })
    );
    return Promise.reject(error);
  }
};

export default api;
