import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import buttonStyles from "../../../styles/button.module.css";
import {
  determineVariantClassForIconButton,
  getIconLinkButtonDisableColor,
  updateIconColor,
  getIconButtonSize
} from "../../../utils/buttonUtil";
import {
  iconButtonSizeTypes,
  iconButtonVariantTypes
} from "../../../types/common";

export default function IconLinkButton({
  icon,
  classes,
  disable,
  variant,
  to,
  iconColor,
  size
}: {
  classes?: string;
  disable?: boolean;
  variant?: iconButtonVariantTypes;
  icon: React.ReactNode;
  to: string;
  iconColor?: string;
  size?: iconButtonSizeTypes;
}) {
  const iconLinkButton = useRef(null);

  useEffect(() => {
    if (iconLinkButton.current)
      updateIconColor(iconLinkButton.current, iconColor, variant, disable);
  }, [iconLinkButton, iconColor, variant, disable]);

  return disable ? (
    <div
      ref={iconLinkButton}
      className={`
        ${getIconLinkButtonDisableColor(variant)}
        ${buttonStyles.iconButton}
        ${getIconButtonSize(size)} ${classes || ""}
      `}
    >
      {icon}
    </div>
  ) : (
    <Link
      ref={iconLinkButton}
      to={to}
      className={`
        ${buttonStyles.iconButton}
        ${determineVariantClassForIconButton(variant)}
        ${getIconButtonSize(size)}
        ${classes || ""}
      `}
    >
      {icon}
    </Link>
  );
}
