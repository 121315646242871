import { useRef, useState } from "react";
import tooltipStyles from "../../styles/tooltip.module.css";
import { useClickOutside } from "../../hooks/useClickOutside";

const Tooltip = ({
  icon,
  children,
  classes
}: {
  icon: React.ReactNode;
  children: React.ReactNode;
  classes?: string;
}) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    setIsTooltipVisible((prev) => !prev);
  };

  useClickOutside(tooltipRef, () => setIsTooltipVisible(false));

  return (
    <div
      className={tooltipStyles.tooltipWrapper}
      onClick={handleClick}
      ref={tooltipRef}
    >
      <div className={tooltipStyles.tooltipIcon}>{icon}</div>
      {isTooltipVisible && (
        <div className={`${tooltipStyles.tooltip}  ${classes || ""}`}>
          {children}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
