import Card from "../UI/Cards/Card";
import CardHeader from "../UI/Cards/CardHeader";
import CardBody from "../UI/Cards/CardBody";
import CardListItem from "../UI/Cards/CardListItem";
import { cardListItem } from "../UI/Cards/CardListItem";

export default function HomePageCard({
  icon,
  title,
  cardItems
}: {
  icon: React.ReactNode;
  title: string;
  cardItems: cardListItem[];
}) {
  return (
    <Card>
      <CardHeader cardTitle={title} icon={icon} />
      <CardBody>
        {cardItems.map((item) => (
          <CardListItem key={item.applicationTitle} cardItems={item} />
        ))}
      </CardBody>
    </Card>
  );
}
