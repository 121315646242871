import { cloneElement } from "react";
import cardStyles from "../../../styles/card.module.css";

export default function CardHeader({
  cardTitle,
  icon,
}: {
  cardTitle: string;
  icon: React.ReactNode;
}) {
  const IconComponent = cloneElement(icon as React.ReactElement, {
    className: cardStyles.cardHeaderIcon,
  });

  return (
    <div className={cardStyles.cardHeader}>
      {IconComponent}
      <p className={cardStyles.cardHeaderText}>{cardTitle}</p>
    </div>
  );
}
