import { toastTypes } from "../components/UI/toastNotification/Toast";
import toastStyles from "../styles/toast.module.css";

class ToastNotificationService {
  static getBackgroundClass = (variant: toastTypes) => {
    switch (variant) {
      case "notification":
        return toastStyles.notificationVariantBg;
      case "warning":
        return toastStyles.warningVariantBg;
      case "error":
        return toastStyles.errorVariantBg;
    }
  };

  static getToastCloseIconColor = (variant: toastTypes) => {
    switch (variant) {
      case "notification":
        return "var(--color-indigo-main)";
      case "warning":
        return "var(--color-yellow-4)";
      case "error":
        return "var(--color-red)";
    }
  };
}

export default ToastNotificationService;
