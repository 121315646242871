import { createSlice } from "@reduxjs/toolkit";
import Keycloak from "keycloak-js";

export interface AuthenticationSlice {
  kcObject: Keycloak | null;
  isLogin: boolean;
}

const initialState: AuthenticationSlice = {
  kcObject: null,
  isLogin: false
};

const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    setKcObject(state, action) {
      state.kcObject = action.payload;
    },
    setIsLogin(state, action) {
      state.isLogin = action.payload;
    }
  }
});

export const { setKcObject, setIsLogin } = authenticationSlice.actions;

export default authenticationSlice.reducer;
