import Toast from "./Toast";
import { useAppSelector } from "../../../store/hooks";
import toastStyles from "../../../styles/toast.module.css";

const ToastsContainer = ({ children }: { children: React.ReactNode }) => {
  const toasts = useAppSelector((state) => state.toastNotificationsSlice);

  return (
    <div>
      {children}
      <div className={`${toastStyles.toastContainer}`}>
        {toasts.map((toasts, i) => (
          <Toast key={toasts.toastId} {...toasts} />
        ))}
      </div>
    </div>
  );
};

export default ToastsContainer;
