import { useState, useEffect } from "react";
import clockStyles from "../../styles/header.module.css";
import TimeService from "../../service/timeService";

export default function Clock() {
  const [currentTime, setCurrentTime] = useState<string>(
    TimeService.getCurrentTime()
  );

  useEffect(() => {
    // Update time every second
    const intervalId = setInterval(() => {
      setCurrentTime(TimeService.getCurrentTime());
    }, 1000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return <div className={clockStyles.clock}>{currentTime}</div>;
}
