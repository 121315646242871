import { useTranslation } from "react-i18next";
import { ReactComponent as BagstageLogo } from "../assets/bagstage_logo.svg";
import { ReactComponent as BarChartIcon } from "../assets/icons/bagstage_analyst.svg";
import { ReactComponent as ArrivalIcon } from "../assets/icons/bagstage_black_flight_landing.svg";
import { ReactComponent as TakeOffIcon } from "../assets/icons/bagstage_flight_takeoff.svg";
import { ReactComponent as LanguageIcon } from "../assets/icons/bagstage_language.svg";
import { ReactComponent as GlobeIcon } from "../assets/icons/bagstage_operator.svg";
import { ReactComponent as QTBMonitorIcon } from "../assets/icons/bagstage_qtb_monitor.svg";
import { ReactComponent as TransferIcon } from "../assets/icons/bagstage_swap_horizontal.svg";
import { ReactComponent as ViewOnlyIcon } from "../assets/icons/bagstage_view_only.svg";
import Footer from "../components/homepage/Footer";
import HomePageCard from "../components/homepage/HomePageCard";
import StandardButton from "../components/UI/buttons/StandardButton";
import { cardListItem } from "../components/UI/Cards/CardListItem";
import PageWrapper from "../components/UI/PageWrapper";
import StyledIcon from "../components/UI/StyledIcon";
import Tooltip from "../components/UI/Tooltip";
import i18n from "../service/translations/translationService";
import URLService from "../service/urlService";
import { useAppSelector } from "../store/hooks";
import homePageStyles from "../styles/homePage.module.css";
import iconStyles from "../styles/icon.module.css";
import tooltipStyles from "../styles/tooltip.module.css";
import { bagstageApplications, languages } from "../utils/commonUtil";

export default function HomePage() {
  const { t } = useTranslation();
  const { isLogin } = useAppSelector((state) => state.authenticationSlice);

  const arrivalLinks: cardListItem[] = [
    {
      appRef: bagstageApplications.arrivalOperator,
      applicationTitle: t("operator-tool"),
      redirectUrl: URLService.generateRedirectUrl(
        window._env_.REACT_APP_ARRIVAL_OPERATOR_URL
      ),
      icon: (
        <StyledIcon icon={<GlobeIcon />} classes={iconStyles.globeIconBorder} />
      )
    },
    {
      appRef: bagstageApplications.arrivalAnalyst,
      applicationTitle: t("analyst-tool"),
      redirectUrl: URLService.generateRedirectUrl(
        window._env_.REACT_APP_ARRIVAL_ANALYST_URL
      ),
      icon: (
        <StyledIcon
          icon={<BarChartIcon />}
          classes={iconStyles.barChartIconBorder}
        />
      )
    }
    // {
    //   appRef: bagstageApplications.arrivalViewOnlyOperator,   // TODO: uncomment it when view only operator needs to be shown
    //   applicationTitle: t("view-only-operator-tool"),
    //   redirectUrl: "/",
    //   icon: (
    //     <StyledIcon
    //       icon={<ViewOnlyIcon />}
    //       classes={iconStyles.viewOnlyIconBorder}
    //     />
    //   )
    // }
  ];

  const departureLinks: cardListItem[] = [
    {
      appRef: bagstageApplications.departureAnalyst,
      applicationTitle: t("analyst-tool"),
      redirectUrl: "/",
      icon: (
        <StyledIcon
          icon={<BarChartIcon />}
          classes={iconStyles.barChartIconBorder}
        />
      )
    }
  ];

  const transferLinks: cardListItem[] = [
    {
      appRef: bagstageApplications.transferQtb,
      applicationTitle: t("qtb-monitor"),
      redirectUrl: URLService.generateRedirectUrl(
        window._env_.REACT_APP_TRANSFER_QTB_URL
      ),
	  icon: (
        <StyledIcon
          icon={<QTBMonitorIcon />}
          classes={iconStyles.qtbMonitorIconBorder}
        />
      )
    }
  ];

  const updateLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  return (
    <PageWrapper>
      <header className={homePageStyles.headerSection}>
        <BagstageLogo className={homePageStyles.bagstageLogo} />
        <Tooltip
          icon={
            <div className={homePageStyles.languageSection}>
              <LanguageIcon />
              <p className={homePageStyles.selectedLanguage}>
                {i18n.language.toUpperCase()}
              </p>
            </div>
          }
          classes={tooltipStyles.tooltipContent}
        >
          <StandardButton
            onClick={() => updateLanguage(languages.da)}
            classes={`
              ${tooltipStyles.tooltipOption}
              ${
                i18n.language === languages.da
                  ? `${tooltipStyles.tooltipOptionSelected}`
                  : ""
              }`}
          >
            Dansk
          </StandardButton>
          <StandardButton
            onClick={() => updateLanguage(languages.en)}
            classes={`
              ${tooltipStyles.tooltipOption}
              ${
                i18n.language === languages.en
                  ? `${tooltipStyles.tooltipOptionSelected}`
                  : ""
              }`}
          >
            English
          </StandardButton>
        </Tooltip>
      </header>

      <section className={homePageStyles.cardSection}>
        <HomePageCard
          icon={<ArrivalIcon />}
          title={t("arrival")}
          cardItems={arrivalLinks}
        />

        <HomePageCard
          icon={<TakeOffIcon />}
          title={t("departure")}
          cardItems={departureLinks}
        />

        <HomePageCard
          icon={<TransferIcon />}
          title={t("transfer")}
          cardItems={transferLinks}
        />
      </section>

      <Footer isLoggedIn={isLogin} />
    </PageWrapper>
  );
}
