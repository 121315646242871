import { createAsyncThunk } from "@reduxjs/toolkit";
import api, { API_PATHS } from "../../service/api";

export enum actionTypes {
  getEnabledProducts = "getEnabledProducts"
}

export const getEnabledProducts = createAsyncThunk(
  actionTypes.getEnabledProducts,
  async () => {
    const response = await api.get(API_PATHS.enabledProducts);
    return response.data;
  }
);
