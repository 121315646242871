import { createSlice } from "@reduxjs/toolkit";
import { getEnabledProducts } from "./action";

interface EnabledProductsSlice {
  enabledProducts?: string[];
  error?: Error | null;
}

const initialState: EnabledProductsSlice = {
  error: null
};

const enabledProductsSlice = createSlice({
  name: "enabled-Products-slice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEnabledProducts.pending, (state) => {
      delete state.error;
      delete state.enabledProducts;
    });
    builder.addCase(getEnabledProducts.rejected, (state, action) => {
      state.error = action.error as Error;
    });
    builder.addCase(getEnabledProducts.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.enabledProducts = action.payload;
      }
    });
  }
});

export default enabledProductsSlice.reducer;
