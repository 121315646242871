import { useEffect } from "react";
import URLService from "../service/urlService";

function RedirectToUserManagement() {
  useEffect(() => {
    window.location.href = URLService.generateRedirectUrl(
      window._env_.REACT_APP_USER_MANAGEMENT_URL
    );
  }, []);

  return null;
}

export default RedirectToUserManagement;
