import { createSlice } from "@reduxjs/toolkit";
import { toastComponentTypes } from "../../components/UI/toastNotification/Toast";
import { generateRandomID } from "../../utils/commonUtil";

const initialState: toastComponentTypes[] = [];

const toastNotificationsSlice = createSlice({
  name: "toast-notifications",
  initialState,
  reducers: {
    addToast(state, action) {
      state.push({ ...action.payload, toastId: generateRandomID() });
    },
    deleteToast(state, action) {
      return state.filter((toast) => toast.toastId !== action.payload);
    }
  }
});

export const { addToast, deleteToast } = toastNotificationsSlice.actions;

export default toastNotificationsSlice.reducer;
