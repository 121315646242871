import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translation from "../translations/translations.json";
import LanguageDetector from "i18next-browser-languagedetector";
import { languages } from "../../utils/commonUtil";

// Initializing i18n with React
i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // Setting up the resources for translations
    resources: translation,
    supportedLngs: [languages.en, languages.da],
    fallbackLng: languages.en,
    // Interpolation allows dynamic insertion of values into translated strings.
    interpolation: {
      // By setting `escapeValue` to false, we instruct i18n not to remove special characters or HTML tags.
      escapeValue: false
    }
  });

export default i18n;
