import iconStyles from "../../styles/icon.module.css";

export default function StyledIcon({
  icon,
  classes
}: {
  icon: React.ReactNode;
  classes?: string;
}) {
  return (
    <div
      className={`
      ${iconStyles.styledIcon}
      ${classes || ""}
      `}
    >
      {icon}
    </div>
  );
}
